// src/App.js
import React from "react";
import Chatbot from "./components/Chatbot";
import { Container, Row, Col } from 'react-bootstrap';

function App() {
    return (
        <Container>
            <Row>
                <Col>
                    <header className="App-header">
                        <Chatbot />
                    </header>
                </Col>
            </Row>
        </Container>
    );
}

export default App;
