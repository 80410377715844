// src/components/Chatbot.js
import React, { useState } from "react";
import axios from "axios";
import { Container, Form, Button, InputGroup, FormControl, Alert, Row, Col } from 'react-bootstrap';

const Chatbot = () => {
    const [query, setQuery] = useState("");
    const [answer, setAnswer] = useState("");
    const [conversation, setConversation] = useState([]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setConversation([...conversation, { sender: 'You', message: query }]);
        try {
            const response = await axios.get(`https://chatbot.vygeek.com/api/answer?query=${query}`);
            setAnswer(response.data.answer);
            setConversation([...conversation, { sender: 'You', message: query }, { sender: 'Chatbot', message: response.data.answer }]);
        } catch (error) {
            setAnswer("No answer found. Please create a ticket at https://tecket.vygeek.com");
            setConversation([...conversation, { sender: 'You', message: query }, { sender: 'Chatbot', message: "No answer found. Please create a ticket at https://tecket.vygeek.com" }]);
        }
        setQuery("");
    };

    return (
        <Container className="mt-5">
            <h1 className="mb-4">Chat with Vybuddy</h1>
            <Row>
                <Col>
                    <Form onSubmit={handleSubmit}>
                        <InputGroup className="mb-3">
                            <FormControl
                                placeholder="Ask with Vybuddy..."
                                aria-label="Ask a question"
                                aria-describedby="basic-addon2"
                                value={query}
                                onChange={(e) => setQuery(e.target.value)}
                            />
                            <Button variant="primary" type="submit">Send</Button>
                        </InputGroup>
                    </Form>
                    <div className="conversation">
                        {conversation.map((chat, index) => (
                            <Alert key={index} variant={chat.sender === 'You' ? 'primary' : 'secondary'}>
                                <strong>{chat.sender}:</strong> {chat.message}
                            </Alert>
                        ))}
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default Chatbot;
